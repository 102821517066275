<template>
<dash-page-new
    :title="entity.name"
    :subtitle="$t('View')"
    :root="$t('Webinars')"
    :root-location="businessDashLink('webinars_student')"
>
  <v-sheet width="100%" max-width="760" class="mx-auto mt-6">
    <v-sheet class="wsRoundedSemi pa-3" :color="wsLIGHTCARD">

      <v-sheet class="wsRoundedSemi overflow-hidden d-flex align-center mb-2">
        <v-img
            :src="entity.image || require(`@/modules/webinars/assets/no_student_webinar.png`)"
            max-width="300"
            width="300"
            class="mr-5"
        />
        <div>
          <h3 class="wsDARKER">{{ entity.name }}</h3>
          <h4 class="font-weight-regular wsACCENT">{{ entity.description }}</h4>
        </div>
      </v-sheet>

      <v-sheet class="wsRoundedSemi pa-2 d-flex align-center mb-2">
        <v-icon :color="wsATTENTION" class="mr-2">mdi-clock</v-icon>
        <h5>
          {{ $t('Start') }} : {{ $time(entity.date_start).regularTime() }}
        </h5>
      </v-sheet>
      <v-sheet class="wsRoundedSemi pa-2 d-flex align-center">
        <v-icon :color="wsATTENTION" class="mr-2">mdi-clock</v-icon>
        <div>
          <ws-timer
              #default="{time}"
              v-if="entity.time_now"
              start-on-mount
              :seconds="$time(entity.date_start).phpTimestamp() - entity.time_now"
          >
            <h5 class="wsDARKER"> {{$t('TimeRemained')}}:  {{ time }} </h5>
          </ws-timer>
        </div>

      </v-sheet>

      <ws-button v-if="displayConnectCondition" label="webinar.student.join" block class="mt-5"></ws-button>


    </v-sheet>

    <ws-text-viewer
        class="mt-5"
        :value="entity.full_description"
        is-public
    />

  </v-sheet>

</dash-page-new>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "StudentWebinar",
  props : {
    webinarUuid : {
      type : String,
    }
  },
  data() {
    return {
      entity : {}
    }
  },
  computed : {
    displayConnectCondition() {

      let secondRemain = this.$time(this.entity.date_start).phpTimestamp() - this.entity.time_now

      return secondRemain < (this.entity.time_before * 60 * 1000 )

    }
  },
  methods : {
    ...mapActions('webinars', [
        'GET_ONE_WEBINAR'
    ]),

    async initPage() {
      const result = await this.GET_ONE_WEBINAR({ uuid: this.webinarUuid , student :true } );

      if (!result) {
        this.ERROR();
        return
      }

      this.entity = await result;

    },
  },
  mounted() {
    this.initPage()
  }
}
</script>


<style scoped>

</style>